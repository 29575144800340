@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';



.dar-download-box-div {
    position: relative;

    form {
        .button-div {
            margin-top: 10px;

            button {
                @include normalButton();
                width: 100%;
                background-color: $green_color;
            }
        }

        .content-box {
            text-align: center;
            padding: 25px 0;

            svg {
                font-size: 35px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .loading-pop {
        position: absolute;
        top: 0;
        right: 0;

        background-color: rgba(240, 240, 240, 0.603);
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        backdrop-filter: blur(1px);
    }
}