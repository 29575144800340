@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';


.search-customer-div {
    .search-border-div {
        display: grid;
        grid-template-columns: 280px auto;
        gap: 30px;

        .filter-border-div {
            .search-section {
                margin-bottom: 15px;

                h5 {
                    color: rgb(77, 77, 77);
                }
            }


            .button-div {
                display: flex;
                justify-content: flex-end;


                button {
                    @include normalButton();
                    width: 100%;
                    background-color: $primary_color;
                    width: 150px;
                }
            }
        }

        .result-div {

            .header-div {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                h4 {
                    font-size: 16px;
                    color: rgb(77, 77, 77);
                }

            }


            .items-div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                gap: 10px;
                margin-top: 15px;

                :nth-child(7n+1) {
                    .profile-image-div {
                        background-color: #b65200;
                    }
                }

                :nth-child(7n+2) {
                    .profile-image-div {
                        background-color: #08807a;
                    }
                }

                :nth-child(7n+3) {
                    .profile-image-div {
                        background-color: #00719e;
                    }
                }

                :nth-child(7n+4) {
                    .profile-image-div {
                        background-color: #7a5e00;
                    }
                }

                :nth-child(7n+5) {
                    .profile-image-div {
                        background-color: #941148;
                    }
                }

                :nth-child(7n+6) {
                    .profile-image-div {
                        background-color: #057939;
                    }
                }

                :nth-child(7n+7) {
                    .profile-image-div {
                        background-color: #bd0000;
                    }
                }

                .profile-div {
                    background-color: rgba(240, 240, 240, 0.616);
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    padding: 15px 10px;
                    width: 160px;
                    overflow: hidden;
                    @include boxShadow();
                    cursor: pointer;
                    position: relative;




                    a {
                        color: inherit;
                        text-decoration: none;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .profile-image-div {
                            width: 60px;
                            height: 60px;
                            color: white;
                            border-radius: 100%;
                            -webkit-border-radius: 100%;
                            -moz-border-radius: 100%;
                            -ms-border-radius: 100%;
                            -o-border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 10px;
                            position: relative;

                            h3 {
                                font-size: 35px;
                            }

                            span {
                                position: absolute;
                                bottom: -10px;
                                left: 50%;
                                transform: translate(-50%, 0);
                                -webkit-transform: translate(-50%, 0);
                                -moz-transform: translate(-50%, 0);
                                -ms-transform: translate(-50%, 0);
                                -o-transform: translate(-50%, 0);
                                border: white 1px solid;
                            }
                        }

                        p {
                            font-size: 10px;
                            font-weight: 600;
                            color: rgb(83, 83, 83);
                            text-align: center;
                        }

                        h4 {
                            font-size: 13px;
                            color: rgb(0, 151, 151);
                            text-align: center;
                        }
                    }


                    .hover-view-div {
                        width: 100%;
                        height: 60px;
                        background: rgb(71, 71, 71);
                        background: linear-gradient(0deg, rgba(71, 71, 71, 1) 12%, rgba(207, 207, 207, 0) 100%);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 15px 15px;
                        cursor: auto;
                        display: none;

                        .icon-with-action-div {
                            width: 30px;
                            height: 30px;
                            border-radius: 100%;
                            -webkit-border-radius: 100%;
                            -moz-border-radius: 100%;
                            -ms-border-radius: 100%;
                            -o-border-radius: 100%;
                            background-color: $edit_color;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            cursor: pointer;
                        }
                    }

                    &:hover {
                        background-color: rgb(233, 233, 233);

                        .hover-view-div {
                            display: flex;
                        }
                    }



                }

            }

            .download-button {
                position: fixed;
                right: 5%;
                bottom: 5%;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                -ms-border-radius: 100%;
                -o-border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: black;
                color: white;
                font-size: 30px;
                cursor: pointer;
                z-index: 1;
            }
        }
    }


    @media screen and (max-width:600px) {
        .search-border-div {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
        }
    }
}