@import '../../../assets/scss/model.scss';
@import '../../../assets/scss/color.scss';


.create-customer-div {
    form {
        .inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0 10px;
        }

        .button-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $primary_color;
            }
        }
    }


    @media screen and (max-width:450px) {
        form {
            .inputs {

                grid-template-columns: 1fr;
                gap: 0px;
            }
        }
    }
}