@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';


.edit-customer-div {
    .inputs {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 10px;

    }

    .button-div {
        display: flex;
        justify-content: flex-end;

        button {
            @include normalButton();
            width: 100%;
            background-color: $primary_color;
            width: 200px;
        }
    }

    .buttons-div {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 15px;
        margin-bottom: 25px;

        button {
            @include normalButton();
            width: 100%;
            background-color: black;
            width: 200px;
        }
    }


    @media screen and (max-width:900px) {
        .inputs {
            grid-template-columns: 1fr 1fr;
            gap: 0 10px;

        }
    }

    @media screen and (max-width:500px) {
        .inputs {
            grid-template-columns: 1fr;
            gap: 0;

        }
    }
}