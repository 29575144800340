@import '../../../assets/scss/model.scss';

.serviced-card-div {
    max-width: 500px;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 15px 0;
    border: 1px rgb(99, 99, 99) dashed;

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    p {
        font-size: 11px;
        font-weight: 600;
    }

    .card-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 15px;
        border-bottom: 1px solid rgb(204, 204, 204);

        .left {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .right {
            position: absolute;
            right: 0;
            top: 0;
            padding: 5px 15px 5px 25px;
            border-bottom-left-radius: 25px;
            color: white;
            opacity: .8;
        }
    }

    .card-content {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        gap: 5px 0;

        .section-one-div {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        .section-two-div {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }


        p {
            background-color: rgb(36, 36, 36);
            color: white;
            padding: 2px 6px;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;

            span {
                background-color: white;
                color: black;
                padding: 0px 4px;
                border-radius: 100px;
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                -ms-border-radius: 100px;
                -o-border-radius: 100px;
                margin-left: 3px;
            }
        }

        .primary {
            background-color: rgb(255, 189, 139);
            color: black;
        }

        .secondary {
            background-color: rgb(151, 153, 255);
            color: black;
        }

        .special {
            background-color: rgb(235, 113, 113);
            color: black;
        }

        .amount {
            background-color: rgb(168, 168, 168);
            color: black;

            span {
                margin: 0;
                margin-right: 3px;
            }
        }
    }

    .card-bottom {
        padding: 5px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgb(204, 204, 204);

        .left {
            display: flex;
            align-items: center;
            gap: 5px;

            p {
                @include threeDots();
            }
        }
    }
}

.install-card {
    background-color: rgb(255, 245, 191);

    a {
        cursor: default;
    }

}