@import '../../../assets/scss/color.scss';
@import '../../../assets/scss/model.scss';

.customer-profile-card-one-div {
    .profile-info {
        width: 100%;
        background-color: rgb(163, 224, 248);
        display: grid;
        grid-template-columns: 70px auto;
        align-items: center;
        padding: 5px 15px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        .profile-img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgb(212, 212, 212);
            background-color: rgb(153, 51, 122);

            h2 {
                font-size: 27px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.863);
                user-select: none;
            }
        }

        .name-div {
            div {
                display: flex;
                align-items: center;
                gap: 5px;

                h4 {
                    @include threeDots()
                }

                span {
                    font-size: 10px;
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }
}