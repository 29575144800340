@import './color.scss';


@mixin normalButton() {
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
    background-color: black;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }
}

@mixin smallButton() {
    @include normalButton();
    font-size: 12px;
    padding: 7px 13px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

@mixin iconInButton() {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-bottom: -1px;
    }
}

@mixin scrollBar() {

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #d6d6d6;
        transition: background 0.2s;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        &:hover {
            background: #b8b8b8;
            width: 10px;
        }
    }


}

@mixin boxShadow() {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

@mixin threeDots($line: 1) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.container {
    padding-left: 5%;
    padding-right: 5%;
}

.loading-icon {
    svg {
        margin-bottom: -1px;
        animation: iconLoading 1.2s linear infinite;
        -webkit-animation: iconLoading 1.2s linear infinite;
    }

    @keyframes iconLoading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

.text-badge {
    font-size: 10px;
    font-weight: 600;
    padding: 2px 6px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    margin: 2px 2px;
    display: inline;
    gap: 3px;
    align-items: center;
    width: max-content;
    background-color: black;
    color: white;
}

.Scheduled-text,
.SSP-text,
.Primary-text {
    background-color: rgb(99, 2, 163);
}

.purple-text {
    background-color: purple;
}

.High-text,
.pink-text,
.Secondary-text {
    background-color: rgb(189, 4, 118);
}

.Closed-text,
.Closed-g-text,
.green-text,
.AMC-text,
.Warranty-text {
    background-color: rgb(86, 121, 5);
}

.Normal-text,
.Proceed-text,
.\I\/W-text,
.Special_work-text {
    background-color: rgb(6, 131, 148);
}

.Pending-text,
.no-text,
.\O\/C-text {
    background-color: rgb(53, 53, 53);
}

.Urgent-text,
.Attending-text,
.Low-text {
    background-color: rgb(247, 140, 0)
}

.\O\/W-text {
    background-color: rgb(143, 108, 12);
}

.red-text,
.Emergency-text,
.Cancelled-text,
.No-text,
.offline-text {
    background-color: $red_color;
}

.btn-icon-div {
    @include normalButton();
    width: 45px;
    height: 35px;
    font-size: 17px;
    display: grid;
    place-content: center;
}