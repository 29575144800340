@import '../../assets/scss/model.scss';

.download-window-div {
    width: 100%;
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(204, 232, 255);

    .box-div {
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: rgb(19, 104, 173);


        .icon-div {
            svg {
                font-size: 85px;
            }
        }

        .h4 {
            font-size: 15px;
            font-weight: 600;
        }

        .button-div {
            display: flex;
            gap: 10px;

            .button {
                @include normalButton();
                background-color: rgb(19, 104, 173);
            }

            .close {
                background-color: rgb(71, 71, 71);
            }
        }
    }

    .red-color {
        color: rgb(209, 53, 6);
    }

    .green-color {
        color: rgb(7, 128, 97);
    }
}